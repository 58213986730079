import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import Layout from "components/Layout";
import SEO from "components/Seo";
import Lab from "components/Lab";
import Eminem from "components/eminemRank";
import GridTemplate from "components/GridTemplate";

// const labJsonQuery = graphql`
//   query {
//     allLabJson {
//       edges {
//         node {
//           id
//           path
//           title
//           date
//           description
//           imageSrc {
//             relativePath
//           }
//         }
//       }
//     }
//   }
// `;

const EminemPage = () => {
  // const data = useStaticQuery(labJsonQuery);

  // const { site } = useStaticQuery(
  //   graphql`
  //     query {
  //       site {
  //         siteMetadata {
  //           descriptionEn
  //         }
  //       }
  //     }
  //   `
  // );

  return (
    <Layout>
      <SEO lang="en" title="Eminem Songs" description="Eminem Top 10" />
      <GridTemplate>
        <Eminem language="en" fullText={true} />
      </GridTemplate>
    </Layout>
  );
};

export default EminemPage;
