import React, { Component } from "react";
import "./eminemRank.css";
import Card from "./Card";
import Content from "components/Content";

// const data = `
// God gave you them shoes to fit you, so put them on and wear them. Be yourself man, be proud of who you are. Even if it sounds corny, don’t let no one tell you, you ain’t beautiful.\n
// The truth is you don’t know what is going to happen tomorrow. Life is a crazy ride, and nothing is guaranteed.\n
// Cause sometimes, you just feel tired. Feel weak. And when you feel weak, you feel like you wanna just give up. But you gotta search within you. You gotta find that inner strength, and just pull that shit out of you. And get that motivation to not give up and not be a quitter.\n
// Everybody has goals, aspirations or whatever, and everybody has been at a point in their life where nobody believed in them…But when I say I’m going to do something, I have to do it.\n
// I realized, ‘Yo, I can’t do anything in moderation. I don’t know how.\n
// If there’s not drama and negativity in my life, all my songs will be really wack and boring or something.\n
// I try to treat all the money I’m making like it’s the last time I’m going to make it.\n
// I started learning how to not be so angry about things, learning how to count my f—ing blessings instead. By doing that, I’ve become a happier person, instead of all this self-loathing I was doing for a while.\n
// Success is my only option, failure’s not.\n
// If you have enemies, good that means you stood up for something.\n
// Behind every successful person lies a pack of haters.\n
// Love is just a word, but you bring it definition.\n
// Don’t let them say you ain’t beautiful. They can all get fu#ked just stay true to you.\n
// Dealing with backstabbers, there was one thing I learned. They’re only powerful when you got your back turned.\n
// When you’re a little kid, you don’t see color, and the fact that my friends were black never crossed my mind. It never became an issue until I was a teenager and started trying to rap.\n
// It sometimes feels like a strange movie, you know, it’s all so weird that sometimes I wonder if it is really happening\n
// I’m just a little bit sicker than the average individual I think.\n
// Imma be what I set out to be without a doubt undoubtedly.\n
// You’d have to walk a thousand miles in my shoes just to see what its like to be me!! 0th Eminem Quote –“Don’t let them tell you ain’t beautiful.”\n
// But music is reflection of self, we just explain it, and then we get our checks in the mail.\n
// Sometimes I feel like rap music is almost the key to stopping racism.\n
// Look, if you had one shot, or one opportunity. To seize everything you ever wanted in one moment. Would you capture it or just let it slip?\n
// Before I was famous, when I was just working in Gilbert’s Lodge, everything was moving in slow motion.\n
// That’s why we seize the moment try to freeze it and own it, squeeze it and hold it.\n
// You better lose yourself in the music, the moment. You own it, you better never let it go. You only get one shot, do not miss your chance to blow. This opportunity comes once in a lifetime, yo\n
// You can try and read my lyrics off of this paper before I lay ’em, But you won’t take the sting out these words before I say ’em\n
// It was my decision to get clean, I did it for me. Admittedly I probably did it subliminally for you.\n
// Yeah, it’s been a ride I guess I had to go to that place to get to this one. Now some of you might still be in that place. If you’re trying to get out, just follow me, I’ll get you there.\n
// Why be a king,when you can be a god?\n
// You don’t get another chance. Life is no Nintendo game.\n
// We’ll walk this road together, through the storm. Whatever weather, cold or warm.\n
// I don’t do ‘black music,’ I don’t do ‘white music’…I make fight music, for high school kids.\n
// And I am, whatever you say I am. If I wasn’t, then why would I say I am?\n
// The moment I used my adversity to my advantage, my career exploded.\n
// Thanks cuz your hate is what gave me this strength.\n
// A normal life is boring.\n
// They can trigger me. But they’ll never figure me out.\n
// I say what I want to say and do what I want to do. There’s no in between. People will either love you for it or hate you for it. 0th Eminem Quote – “If people take anything from my music, it should be motivation to know that anything is possible as long as you keep working at it and don’t back down.”\n
// Nobody likes to fail. I want to succeed in everything I do, which isn’t much. But the things that I’m really passionate about, if I fail at those, if I’m not successful, what do I have?\n
// People can try to reinvent themselves. I don’t think you can really change who you are, though, because who you are is pretty much where you came from and what you’ve done up to now.\n
// You can make something of your life. It just depends on your drive.\n
// Be proud of who you are.\n
// In my mind I’ma fighter, my heart’s a lighter, my soul is the fluid. My flow sparks it right up.\n
// You gotta find that inner strength and just pull that s**t out of you. And get that motivation to not give up and not be a quitter, no matter how bad you wanna just fall on your face and collapse.\n
// We sing for these kids who don’t have a thing.\n
// Trust is hard to come by. That’s why my circle is small and tight. I’m kind of funny about making new friends.\n
// I was poor white trash, no glitter, no glamour, but I’m not ashamed of anything.\n
// Hip-hop is ever changing but you’ll always have the pack. And you’ll always have those people who are separated from the pack.\n
// Sporadic thoughts will pop into my head and I’ll have to go write something down, and the next thing you know I’ve written a whole song in an hour.\n
// The writing process, the way I go about it is I do whatever the beat feels like, whatever the beat is telling me to do. Usually when the beat comes on, I think of a hook or the subject I want to rap about almost instantly. Within four, eight bars of it playing I’m just like, ‘Oh, OK. This is what I wanna do’.\n
// Honestly, I’d love to be remembered as one of the best to ever pick up a mic, but if I’m doing my part to lessen some racial tension I feel good about what I’m doing.\n
// I don’t think I’ve ever read poetry, ever. I’m not really book-smart.\n
// There was certainly, like, a rebellious, like, youthful rage in me. And there was also the fact of no getting away from fact that I am white, and you know, this is predominantly black music, you know.\n
// Five or six songs leaked from the original version of ‘Encore.’ So I had to go in and make new songs to replace them.\n
// I have a slight bit of OCD, I think. I’m not walking around flipping light switches. But when I say I’m going to do something, I have to do it.\n
// I need to keep working on myself for a while.\n
// Yeah, I did see where the people dissing me were coming from. But, it’s like, anything that happened in the past between black and white, I can’t really speak on it, because I wasn’t there. I don’t feel like me being born the color I am makes me any less of a person.\n
// Say there’s a white kid who lives in a nice home, goes to an all-white school, and is pretty much having everything handed to him on a platter – for him to pick up a rap tape is incredible to me, because what that’s saying is that he’s living a fantasy life of rebellion.\n
//  Ultimately, who you choose to be in a relationship with and what you do in your bedroom is your business.”\n
// Hip-hop saved my life, man. It’s the only thing I’ve ever been even decent at. I don’t know how to do anything else.\n
// A lot of my rhymes are just to get chuckles out of people. Anybody with half a brain is going to be able to tell when I’m joking and when I’m serious.\n
// I didn’t have nothin’ going for me… school, home… until I found something I loved, which was music, and that changed everything.\n
// It creeps me out sometimes to think of the person I was. I was a terrible person. I was mean to people.\n
// I was a smart kid, but I hated school.\n
// Anybody with a sense of humor is going to put on my album and laugh from beginning to end.\n
// There was a while when I was feeling like, ‘Damn, if I’d just been born black, I would not have to go through all this’.\n
// I stopped watching TV because of ‘The Wire.’ Like, ‘The Wire’ ruined everything for me because I don’t even want to watch anything else now.\n
// `;

const data = require("./quotes.json");

export class Eminem extends Component {
  state = {
    currentQuote: "",
  };

  handleClick = () => {
    // var quotes = data.split("\n");
    var quotes = JSON.parse(JSON.stringify(data));
    // console.log(quotes["text"]);

    quotes = quotes.filter((e) => e);

    console.log(quotes);

    var myRandom = Math.round(Math.random() * quotes.length);

    // console.log(quotes.length);
    // console.log(myRandom);

    console.log(quotes[myRandom]);

    this.setState({
      currentQuote: quotes[myRandom]["text"],
    });
  };

  createCard = () => {
    return <Card quote={this.state.currentQuote} />;
  };

  render() {
    return (
      <div className="App">
        <div className="container">
          {/* <h1 className="title">Random Eminem Lyrics</h1> */}
          <img src={require("/content/assets/recovery.png")} alt="Eminem" />
          <button className="get-quote-button" onClick={this.handleClick}>
            lyrics
          </button>
          {this.createCard()}
        </div>
      </div>
    );
  }
}

// Eminem.propTypes = {
//   language: PropTypes.string,
//   fullText: PropTypes.bool,
// };

export default Eminem;
