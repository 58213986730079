import React from "react";

const Card = (props) => {
  return (
    <div className="quote-container">
      <p className="quote">{props.quote ? `"${props.quote}"` : null}</p>
      <p className="em">{props.quote ? "- Satoshi Nakamoto" : null}</p>
    </div>
  );
};

export default Card;
